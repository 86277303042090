<ge-modal-window>
    <div class="container">
        <p
            [translateMe]="'news.modal.areYouSureDeleteArticle'"
            data-translate-me
        >
            {{ "news.modal.areYouSureDeleteArticle" | i18n }}
        </p>
    </div>
</ge-modal-window>
