export const NEW_FRAGMENT = `
    {
        id
        publishDate
        unPublishDate
        text
        thumbnailLink
        title
        thumbnail{
            externalFileName
            fileType
        }
    }
`;
