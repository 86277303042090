import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "../../../shared/components/modal-window/modal-options.interface";
import { NewsService } from "../services/news.service";
import { New } from "../interfaces/new.interface";
import { NewsFilterInterface } from "../interfaces/news-filter.interface";
import { ModalWindowService } from "../../../shared/components/modal-window/modal-window.service";

@Component({
    selector: "ge-news-remove-modal",
    templateUrl: "./news-remove-modal.component.html",
    styleUrl: "./news-remove-modal.component.scss",
})
export class NewsRemoveModalComponent implements OnInit {
    public news: New[];
    public data: any;
    public page = 0;
    public filter: NewsFilterInterface;
    public orderOfSort = "Ascending";
    public sortBy = "Title";

    modalWindowOptions: IModalWindowOptions = {
        ngbOptions: {
            windowClass: "extra-lg",
        },
        title: "news.modal.articleDeletion",
        buttons: [
            {
                label: "news.modal.delete",
                click: this.save.bind(this),
                disabled: this.isSubDisabled.bind(this),
            },
        ],
    };
    constructor(
        private newsService: NewsService,
        protected modalWindowService: ModalWindowService,
    ) {}

    ngOnInit() {}
    public save() {
        const { id } = this.data.article;
        this.newsService.deleteNew(id).subscribe((articleId) => {
            this.closeModal();
        });
    }
    private closeModal(data?: any): void {
        this.modalWindowService.close(data);
    }
    public isSubDisabled() {}
}
