<ge-modal-window>
    <div class="container">
        <form [formGroup]="newsFormGroup">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <!-- Publish Date. -->
                            <ng-container
                                *ngTemplateOutlet="publishDate"
                            ></ng-container>
                        </div>
                        <div class="col-6">
                            <!-- Unpublish Date. -->
                            <ng-container
                                *ngTemplateOutlet="unPublishDate"
                            ></ng-container>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <!-- Title. -->
                                <ng-container
                                    *ngTemplateOutlet="title"
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <!-- Letter. -->
                                <ng-container
                                    *ngTemplateOutlet="letter"
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <!-- Thumbnail. -->
                                <ng-container
                                    *ngTemplateOutlet="thumbnail"
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #publishDate>
                    <div class="row">
                        <div
                            class="col-12 flex_block align-items-center date-container"
                        >
                            <div class="col-4">
                                <span
                                    class="align-items"
                                    data-translate-me
                                    [translateMe]="'news.modal.publishDate'"
                                    >{{ "news.modal.publishDate" | i18n }}</span
                                >
                            </div>
                            <div class="col-8 flex_block">
                                <ge-date-range
                                    [range]="publishDateTime"
                                    (rangeSelection)="
                                        onDateChanged($event, true)
                                    "
                                ></ge-date-range>
                                <ge-masked-time-input
                                    [time]="
                                        data.article && data.article.publishDate
                                    "
                                    (onTimeChanged)="onTimeChange($event, true)"
                                ></ge-masked-time-input>
                            </div>
                        </div>
                    </div>
                    <ge-form-error-messages
                        [formElement]="newsFormGroup.controls.publishDate"
                    ></ge-form-error-messages>
                </ng-template>
                <ng-template #unPublishDate>
                    <div class="row">
                        <div
                            class="col-12 flex_block align-items-center date-container"
                        >
                            <div class="col-4 px-0">
                                <span
                                    data-translate-me
                                    [translateMe]="'news.modal.unPublishDate'"
                                    >{{
                                        "news.modal.unPublishDate" | i18n
                                    }}</span
                                >
                            </div>
                            <div class="col-1 px-0 checkbox-container">
                                <ge-checkbox
                                    [checkboxValue]="!isUnsetUnpublishDate"
                                    [checked]="!isUnsetUnpublishDate"
                                    (checkboxToggle)="toggleUnpublishDate()"
                                ></ge-checkbox>
                            </div>
                            <div
                                *ngIf="!isUnsetUnpublishDate"
                                class="col-7 mr-1 flex_block"
                            >
                                <ge-date-range
                                    [range]="unPublishDateTime"
                                    [publishDate]="publishDateTime"
                                    (rangeSelection)="onDateChanged($event)"
                                ></ge-date-range>
                                <ge-masked-time-input
                                    [time]="
                                        data.article &&
                                        data.article.unPublishDate
                                    "
                                    (onTimeChanged)="onTimeChange($event)"
                                ></ge-masked-time-input>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ge-form-error-messages
                            [formElement]="newsFormGroup.controls.unPublishDate"
                        >
                        </ge-form-error-messages>
                    </div>
                </ng-template>
                <ng-template #title>
                    <div class="row">
                        <div class="col-12 flex_block margin-top-20">
                            <div class="col-2">
                                <span
                                    data-translate-me
                                    [translateMe]="'news.modal.title'"
                                    >{{ "news.modal.title" | i18n }}</span
                                >
                            </div>
                            <div class="col-10">
                                <input
                                    id="title"
                                    type="text"
                                    class=""
                                    name="title"
                                    formControlName="title"
                                    required="true"
                                />
                            </div>
                            <ge-form-error-messages
                                [formElement]="newsFormGroup.controls.title"
                            ></ge-form-error-messages>
                        </div>
                    </div>
                </ng-template>
                <ng-template #letter>
                    <div class="margin-top-20 flex_block">
                        <div class="col-2">
                            <span
                                data-translate-me
                                [translateMe]="'news.modal.letter'"
                                >{{ "news.modal.letter" | i18n }}</span
                            >
                        </div>
                        <div class="col-10">
                            <ge-text-editor
                                formControlName="text"
                                required="true"
                            ></ge-text-editor>
                        </div>

                        <ge-form-error-messages
                            [formElement]="newsFormGroup.controls.text"
                        ></ge-form-error-messages>
                    </div>
                </ng-template>
                <ng-template #thumbnail>
                    <div class="row">
                        <div class="col-12 flex_block margin-top-20">
                            <div class="col-2">
                                <span
                                    data-translate-me
                                    [translateMe]="'news.modal.thumbnail'"
                                    >{{ "news.modal.thumbnail" | i18n }}</span
                                >
                            </div>
                            <div class="col-10">
                                <div
                                    class="upload"
                                    [ngClass]="{
                                        'with-attachments': attachments.length,
                                    }"
                                >
                                    <div *ngIf="attachments.length">
                                        <ge-attachment-preview
                                            [isEdit]="true"
                                            [attachment]="attachments"
                                            (onChange)="
                                                removeAttachment($event)
                                            "
                                        ></ge-attachment-preview>
                                    </div>
                                    <div
                                        class="geupload"
                                        [class.hide]="attachments.length"
                                    >
                                        <ge-upload
                                            #attachmentUploader
                                            dropAllowed="true"
                                            [multiple]="true"
                                        ></ge-upload>
                                    </div>
                                </div>
                            </div>
                            <ge-form-error-messages
                                [formElement]="newsFormGroup.controls.title"
                            ></ge-form-error-messages>
                        </div>
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
</ge-modal-window>
