/* src/app/management/news/news-modal/news-modal.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#title {
  width: 100%;
  height: 40px;
}
.flex_block {
  display: flex;
  justify-content: flex-start;
}
.flex_block .margin-right-sm {
  margin-left: 1rem;
}
.upload {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.upload div {
  flex-grow: 1;
}
.upload .geupload {
  min-width: 48.8%;
}
.uploader {
  padding-top: 25px;
}
.align-items {
  align-items: center;
}
.hide {
  display: none;
}
.date-container {
  min-height: 40px;
}
.checkbox-container {
  height: 100%;
}
/*# sourceMappingURL=news-modal.component.css.map */
