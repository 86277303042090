import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { NewsFilterInterface } from "../interfaces/news-filter.interface";
import { NewsSortInterface } from "../interfaces/news-sort.interface";
import { GraphqlService } from "src/app/services/graphql.service";
import { pluck } from "rxjs/operators";
import { New } from "../interfaces/new.interface";
import gql from "graphql-tag";
import { NEW_FRAGMENT } from "../fragments/new.fragment";
import { UploadService } from "src/app/shared/components/upload/upload.service";

@Injectable({
    providedIn: "root",
})
export class NewsService {
    constructor(
        private uploadService: UploadService,
        private graphqlService: GraphqlService,
    ) {}

    public getNews(
        take: number,
        skip: number,
        newsFilter?: NewsFilterInterface,
        sortingOrder?: string,
        orderBy?: string,
        id?: number,
    ): Observable<New[]> {
        const query = gql`
            query ($newsFilter: NewsFilter, $id: Int, $take: Int!, $skip: Int!, $sortingOrder: ListSortDirection, $orderBy: String) {
                news(newsFilter: $newsFilter, id: $id, take: $take, skip: $skip, sortingOrder: $sortingOrder, orderBy: $orderBy)
                ${NEW_FRAGMENT}
            }`;

        const variables = {
            newsFilter,
            id,
            take,
            skip,
            sortingOrder,
            orderBy,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "news"));
    }

    public addNew(
        text: string,
        title: string,
        publishDate?: Date,
        unPublishDate?: Date,
    ): Observable<New> {
        const query = `
        mutation addArticle(
            $article: ArticleAdd = null
        ) { addArticle( article: $article) 
            ${NEW_FRAGMENT}
        }
    `;
        const article = {
            text,
            title,
            publishDate,
            unPublishDate,
        };
        const variables = {
            article,
        };

        const graphqlData = {
            operationName: "addArticle",
            query,
            variables,
        };

        this.uploadService.append("graphQLData", JSON.stringify(graphqlData));

        return this.uploadService.upload().pipe(pluck("data", "addArticle"));
    }

    public editNew(
        id: number,
        text: string,
        title: string,
        isThumbnailEdited: boolean,
        isThumbnailDeleted: boolean,
        publishDate?: Date,
        unPublishDate?: Date,
    ): Observable<New> {
        const query = `
            mutation editArticle ($article: ArticleEdit, $isThumbnailEdited: Boolean!, $isThumbnailDeleted: Boolean! ) {
                editArticle (article: $article, isThumbnailEdited: $isThumbnailEdited, isThumbnailDeleted: $isThumbnailDeleted)
                ${NEW_FRAGMENT}
                
            }
        `;
        const article = {
            id,
            text,
            title,
            publishDate,
            unPublishDate,
        };

        const variables = {
            article,
            isThumbnailEdited,
            isThumbnailDeleted,
        };

        const graphqlData = {
            operationName: "editArticle",
            query,
            variables,
        };

        this.uploadService.append("graphQLData", JSON.stringify(graphqlData));

        return this.uploadService.upload().pipe(pluck("data", "editArticle"));
    }

    public deleteNew(articleId: number): Observable<string> {
        const mutation = gql`
            mutation ($articleId: Int) {
                deleteArticle(articleId: $articleId)
            }
        `;

        const variables = {
            articleId,
        };

        return this.graphqlService
            .mutate(mutation, variables)
            .pipe(pluck("data", "deleteArticle"));
    }

    public newsScrollTop$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    public newsPageChange$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    public newsSort$: BehaviorSubject<NewsSortInterface> =
        new BehaviorSubject<NewsSortInterface>({
            sortBy: null,
            orderOfSort: null,
        });
}
