import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { UploadComponent } from "../../../shared/components/upload/upload/upload.component";
import { ClientEditPackage } from "../../../relations/clients/client-types/client-edit-package";
import { AttachmentRemoveEvent } from "../../../shared/components/attachment-preview/attachment-remove-event.type";
import moment from "moment";
import { IDriverHoursLineResponse } from "../../../driver-hours/shared/driver-hours.model";
import { IModalWindowOptions } from "../../../shared/components/modal-window/modal-options.interface";
import { New } from "../interfaces/new.interface";
import { NewsService } from "../services/news.service";
import { NewsFilterInterface } from "../interfaces/news-filter.interface";
import { ModalWindowService } from "../../../shared/components/modal-window/modal-window.service";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "ge-news-modal",
    templateUrl: "./news-modal.component.html",
    styleUrl: "./news-modal.component.scss",
})
export class NewsModalComponent
    implements OnInit, AfterViewInit, AfterContentChecked
{
    public pageIsReady = false;
    public newsFormGroup: UntypedFormGroup;
    public attachments: any[] = [];
    public readonly timeFormat: string = "HH:mm";
    public endDayTime: Date;
    public startDayTime: Date;
    public overlappingLines: IDriverHoursLineResponse[];
    public data: any;
    public page = 0;
    public filter: NewsFilterInterface;
    public orderOfSort = "Ascending";
    public sortBy = "Title";
    public news: New[];
    public article;
    public date = new Date();
    public showConfirm = new BehaviorSubject(false);
    public confirmationOptions = {
        title: "news.modal.cancelArticleCreation",
        message: "news.modal.cancelArticleCreationMessage",
        showConfirm: this.showConfirm,
        callback: (isCloseWindowConfirmed: boolean) =>
            isCloseWindowConfirmed && this.modalWindowService.close(),
    };

    @ViewChild("attachmentUploader")
    attachmentUploader: UploadComponent;
    modalWindowOptions: IModalWindowOptions = {
        ngbOptions: {
            windowClass: "extra-lg",
        },
        title: "news.modal.editArticle",
        buttons: [
            {
                label: "news.modal.publish",
                click: this.save.bind(this),
                disabled: this.isSubDisabled.bind(this),
            },
        ],
    };

    public publishDateTime: moment.Moment;
    public unPublishDateTime: moment.Moment;
    protected clientPack: ClientEditPackage = new ClientEditPackage();
    isUnsetUnpublishDate = true;
    isThumbnail = false;
    isThumbnailEdited = false;
    isThumbnailDeleted = false;
    constructor(
        private fb: UntypedFormBuilder,
        private newsService: NewsService,
        protected modalWindowService: ModalWindowService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.createForm();
        this.article = this.data.article;
        const thumbnailLink = this.article ? this.article.thumbnailLink : null;
        const thumbnail = this.article.thumbnail;
        if (thumbnailLink) {
            this.attachments.push({
                url: thumbnailLink,
                name: thumbnail.externalFileName,
                type: thumbnail.fileType,
            });
            this.isThumbnail = true;
        }
    }

    ngAfterViewInit() {
        this.attachmentUploader.onChanged.subscribe((file) => {
            this.attachments.push(file);
            if (this.isThumbnail) {
                this.isThumbnailEdited = true;
                this.isThumbnailDeleted = false;
            }
        });
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    private createForm() {
        const article = this.data.article;

        this.publishDateTime =
            article && article.publishDate
                ? moment.utc(article.publishDate).local()
                : moment();
        if (article && article.unPublishDate) {
            this.isUnsetUnpublishDate = false;
            this.unPublishDateTime = moment.utc(article.unPublishDate).local();
        } else {
            this.isUnsetUnpublishDate = true;
        }

        this.newsFormGroup = this.fb.group({
            publishDate: [this.publishDateTime, [Validators.required]],
            unPublishDate: [this.unPublishDateTime],
            title: [
                article && article.title ? article.title : "",
                [Validators.required],
            ],
            text: [
                article && article.text ? article.text : "",
                [Validators.required],
            ],
            attachments: "",
        });

        setTimeout(() => {
            this.newsFormGroup.valueChanges.subscribe(() => {
                this.showConfirm.next(true);
            });
        });
    }

    onDateChanged(data, isPublish?: boolean) {
        let date = moment(data).startOf("day");
        const time = moment(
            isPublish ? this.publishDateTime : this.unPublishDateTime,
        ).format("HH:mm");
        const [hours, minutes] = time.split(":");
        const publishDate = this.newsFormGroup.get("publishDate");
        const unPublishDate = this.newsFormGroup.get("unPublishDate");

        date = date.add(hours, "hours").add(minutes, "minutes");

        if (isPublish) {
            this.publishDateTime = date;
            this.unPublishDateTime = unPublishDate.value;
        } else {
            this.publishDateTime = publishDate.value;
            this.unPublishDateTime = date;
        }

        const isWrongDatesOrder: boolean = moment(this.publishDateTime).isAfter(
            this.unPublishDateTime,
        );
        if (isWrongDatesOrder) {
            this.unPublishDateTime = this.publishDateTime;
        }

        publishDate.setValue(this.publishDateTime);
        unPublishDate.setValue(this.unPublishDateTime);
    }

    onTimeChange(e: string, isPublish?: boolean): void {
        let date = moment(
            this.newsFormGroup.get(isPublish ? "publishDate" : "unPublishDate")
                .value,
        ).startOf("day");

        const [hours, minutes] = e.split(":");

        date = date.add(hours, "hours").add(minutes, "minutes");
        this.newsFormGroup
            .get(isPublish ? "publishDate" : "unPublishDate")
            .setValue(date);
        isPublish
            ? (this.publishDateTime = date)
            : (this.unPublishDateTime = date);
    }

    private save() {
        this.addAttachment();

        if (this.article) {
            const newArticle = { ...this.article, ...this.newsFormGroup.value };

            this.newsService
                .editNew(
                    this.article.id,
                    newArticle.text,
                    newArticle.title,
                    this.isThumbnailEdited,
                    this.isThumbnailDeleted,
                    newArticle.publishDate,
                    newArticle.unPublishDate,
                )
                .subscribe((response) => {
                    this.closeModal(response);
                });
        } else {
            const newArticle = this.newsFormGroup.value;
            this.newsService
                .addNew(
                    newArticle.text,
                    newArticle.title,
                    newArticle.publishDate,
                    newArticle.unPublishDate,
                )
                .subscribe((response) => {
                    this.closeModal(response);
                });
        }
    }

    private closeModal(data?: any): void {
        this.modalWindowService.close(data);
    }

    protected addAttachments() {
        this.attachmentUploader.attach();
    }

    private isSubDisabled() {
        return this.newsFormGroup.invalid;
    }

    public removeAttachment(event: AttachmentRemoveEvent) {
        const { index } = event;

        this.attachmentUploader.files.splice(index, 1);
        this.markFormAsDirty();
        if (this.isThumbnail) {
            this.isThumbnailDeleted = true;
            this.isThumbnailEdited = false;
        }
    }

    public addAttachment() {
        this.attachmentUploader.attach();
        this.markFormAsDirty();
    }

    private markFormAsDirty(): void {
        this.newsFormGroup.markAsDirty({ onlySelf: true });
    }

    private toggleUnpublishDate() {
        this.isUnsetUnpublishDate = !this.isUnsetUnpublishDate;
        this.isUnsetUnpublishDate
            ? (this.unPublishDateTime = null)
            : (this.unPublishDateTime = moment());

        this.newsFormGroup
            .get("unPublishDate")
            .setValue(this.unPublishDateTime);
    }
}
